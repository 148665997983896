import logo from './logo.svg';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, useStore } from "react-redux";
import './App.css';
import {Row, Column, Axis} from "./layout";
import { Button } from '@mui/material';
import { Axios } from 'axios';
import "./dnd.scss";
import axios from 'axios';
import uuid from 'react-uuid';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import SendIcon from '@mui/icons-material/Send';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgressWithLabel from './Progress';
import _ from 'lodash';



function App() {
  const dispatch = useDispatch();
  const stateObj = useSelector((state) => state.mainState);
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [percent, setPercent] = useState(0);
  const [progress, setProgress] = useState(false);
  const fileRef = React.createRef()
  const [openDialog, setOpenDialog] = React.useState(false);
  const [processData, setProcessData] = useState([]);
  //const [metal, setMetal] = React.useState(null);
  //const [gem, setGem] = React.useState(null);
  const $ = window.$;

  const metalType = [
    {name: "Gold Yellow B2", value:1},
    /*{name: "Gold Yellow B1", value:2},
    {name: "Gold White B1", value:3},
    {name: "Gold White B2", value:4},*/
    {name: "Gold Rose B2", value:5},
    /*{name: "Gold Rose B1", value:6},
    {name: "XGold White V1", value:7},
    {name: "XGold White V2", value:8},
    {name: "XGold White V3", value:9},*/
    {name: "XGold White V4", value:10},
    /*{name: "XGold White V5", value:11},
    {name: "Gold Black Satin V9", value:12},
    {name: "Gold White Scratch V8", value:13},
    {name: "Zirconium V6", value:14},
    {name: "Zirconium Black V7", value:15},*/
    {name: "Platinum Polished", value:16},
  ]

  const gemType = [
    {name: "Diamond White", value:1},
    {name: "Emerald", value:2},
    {name: "Red Ruby", value:3},
    {name: "Blue Sapphire", value:4},
    {name: "Yellow Sapphire", value:5},
    {name: "Aquamarine", value:6},
    {name: "Garnet", value:7},
    {name: "Tanzanite", value:8},
  ]

  useEffect(()=>{
    const intervalId = setInterval(()=>{
      axios.post("https://render.jrcastings.space/api.php", {command:"get_status"}).then((result)=>{
        //console.log(result.data);
        setProcessData(result.data);
      });
    }, 2000);
    
    setTimeout(() => {
      clearInterval(intervalId);
      console.log('stop')
    }, 60000*30); // 60000 = 1 minute
  },[]);

  const handleClickOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  function drag(ev) {
    ev.dataTransfer.setData("text", "foo");
  }

  function leaveDropZone(ev) {
    //$(ev.target).removeAttr("drop-active");
    $(ev.target).removeClass("drop-active");
  }
  
  function drop(ev) {
    ev.preventDefault();
    $(ev.target).removeClass("drop-active");
    if (ev.dataTransfer.items) {
      for (let item of ev.dataTransfer.items) {
        if (item.kind === 'file') {
          const file = item.getAsFile();
          console.log('File name:', file.name);
          uploadFile(file);
        }
      }
    } /*else {
      for (let file of ev.dataTransfer.files) {
        console.log('File name:', file.name);
      }
    }*/
  }

  function allowDrop(ev) {
    $(ev.target).addClass("drop-active");
    ev.preventDefault();
  }

  const handleFileChange = (event) => {
    let file = event.target.files[0];
    setSelectedFile(file);
    uploadFile(file);
  };

  function uploadFile(file) {
    if (!file) {
      return;
    }
    if (!_.endsWith(file.name, ".3dm")) {
      return;
    }
    let tempFiles = [...files];
    tempFiles.push({id:uuid(), file:file, name:file.name, metal:metalType[0].value, gem:gemType[0].value, video: true, progress:false, disabled:false});
    setFiles(tempFiles);
  }

  const handleBrowseFile = () => {
    fileRef.current.click();
  }

  const handleDelete = (event) => {
    let id = $(event.currentTarget).closest('tr').attr('id');
    let tempFiles = [...files];
    tempFiles = _.filter(tempFiles, x => x.id !== id);
    setFiles(tempFiles);
  }

  const handleRender = (event) => {
    let id = $(event.currentTarget).closest('tr').attr('id');
    let tempFiles = [...files];
    let item = _.find(tempFiles, x => x.id === id);
    const formData = new FormData();
    formData.append('files', item.file, item.file.name, item.metal, item.gem, item.video);
    formData.append("metal", _.find(metalType, x => x.value === item.metal).name);
    formData.append("gem", _.find(gemType, x => x.value === item.gem).name);
    formData.append("video", item.video === true ? 1 : 0); 
    let email = "jr.castings.ny@gmail.com";
    if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
      email = "mtk.internet@gmail.com";
    }
    console.log(email);
    formData.append("user_email", email); 
    _.forEach(tempFiles, (f, i) => {
      if (f.id === item.id) {
        tempFiles[i].progress = true;
      } else {
        tempFiles[i].progress = false;
      }
      tempFiles[i].disabled = true;
    });
    setFiles(tempFiles);
    axios.post("https://render.jrcastings.space/upload.php", formData, {maxBodyLength: Infinity, maxContentLength: Infinity, onUploadProgress: (e)=>{     
      let percent = Math.floor((100 * e.loaded) /e.total);
      console.log(percent);
      setPercent(percent);
    }}).then((result)=>{
      _.forEach(tempFiles, (f, i) => {
        tempFiles[i].progress = false;
        tempFiles[i].disabled = false;
      });
      tempFiles = _.filter(tempFiles, x => x.id !== id);
      setFiles(tempFiles);
      if (tempFiles.length === 0) {
        //setOpenDialog(true);
      }
      if (result.status === 200) {
        console.log("finish uploading");
      }
    });
  }

  const handleChangeMetal = (event) => {
    let id = $(`#${event.target.name}`).closest('tr').attr('id');
    let tempFiles = [...files];
    let i = _.findIndex(tempFiles, (x) => x.id === id);
    tempFiles[i].metal = event.target.value;
    setFiles(tempFiles);
  };

  const handleChangeGem = (event) => {
    let id = $(`#${event.target.name}`).closest('tr').attr('id');
    let tempFiles = [...files];
    let i = _.findIndex(tempFiles, (x) => x.id === id);
    tempFiles[i].gem = event.target.value;
    setFiles(tempFiles);
  };

  const handleVideo = (event) => {
    let id = $(event.currentTarget).closest('tr').attr('id');
    let tempFiles = [...files];
    let i = _.findIndex(tempFiles, (x) => x.id === id);
    tempFiles[i].video = event.currentTarget.checked;
    setFiles(tempFiles);
  }

  return (
    <div className="App">
      <Column center>
        {files.length > 0 || processData.length > 0 ?
        <Row top={40} width={1420} main={Axis.main.between}>
          <Column width={300}>
            <div className="file-uploader" onDragOver={allowDrop} onDragLeave={leaveDropZone} onDrop={drop}>
              <div style={{position:"absolute", zIndex:1, width:'100%', height:'100%'}}>
                <p style={{marginTop:130}}>Drap & drop your 3D files here</p>
              </div>
              <div style={{position:"absolute", zIndex:10, width:'100%', height:'100%'}}>
                <Row top={30} main={Axis.main.center}>
                  <Button variant="contained" onClick={handleBrowseFile}>Browse</Button>
                  <input id="file" type="file" hidden onChange={handleFileChange} ref={fileRef} accept='.3dm'/>
                </Row>
              </div>
            </div>
          </Column>
          <Column left={40} sx={{overflowY:'auto'}}>
            {files.length > 0 &&
              <table>
                <thead><tr><th className='thead-left'>#</th><th className='thead-left'>Name</th><th className='thead-center'>Metal</th><th className='thead-center'>Gem</th><th className='thead-center'>Video</th><th className='thead-center'>Delete</th><th className='thead-center'>Render</th></tr></thead>
                <tbody>
                {files.map((f, i)=>{
                  return(
                  <tr key={f.id} id={f.id}>
                    <td><Row sx={{minWidth:40}}>{i+1}.</Row></td>
                    <td><Row width={350}><p>{f.name}</p></Row></td>
                    <td><Row width={200}>
                      <FormControl sx={{ m: 1, minWidth: 170 }}>
                        {/*<InputLabel id="select-metal">Metal</InputLabel>*/}
                        <Select
                          id={"metal-"+f.id}
                          name={"metal-"+f.id}
                          value={f.metal === "" ? metalType[0].value : f.metal}
                          fullWidth
                          onChange={handleChangeMetal}
                        >
                          {metalType.map((item)=>{
                            return(
                              <MenuItem key={"metal"+item.value} value={item.value}>{item.name}</MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </Row></td>
                    <td><Row width={200}>
                      <FormControl sx={{ m: 1, minWidth: 170 }}>
                        {/*<InputLabel id="select-gem">Gem</InputLabel>*/}
                        <Select
                          id={"gem-"+f.id}
                          name={"gem-"+f.id}
                          value={f.gem === "" ? gemType[0].value : f.gem}
                          fullWidth
                          onChange={handleChangeGem}
                        >
                          {gemType.map((item)=>{
                            return(
                              <MenuItem key={"gem"+item.value} value={item.value}>{item.name}</MenuItem>
                            )
                          })}
                        </Select>
                      </FormControl>
                    </Row></td>
                    <td><Row width={70} center><Checkbox checked={f.video} onChange={handleVideo} inputProps={{ 'aria-label': 'controlled' }}/></Row></td>
                    <td><Row width={70} center><IconButton aria-label="delete" onClick={handleDelete}><DeleteIcon /></IconButton></Row></td>
                    <td><Row left={10} width={100} center>
                      { f.progress === false ?
                        <Button variant='contained' onClick={handleRender} disabled={f.disabled}>Render</Button>
                        :
                        <CircularProgress size={25}></CircularProgress>
                      }
                      </Row>
                    </td>
                    
                  </tr>
                  )
                })}
                </tbody>
              </table>
            }
            {processData.length > 0 &&
              <Column top={files.length > 0 ? 40 : 0} sx={{minWidth:1070}}>
                <table>
                  <thead><tr>
                    <th className='thead-left'>File</th>
                    <th className='thead-center'>Image Status</th>
                    <th className='thead-center'>Video Status</th>
                  </tr></thead>
                  <tbody>
                    {processData.map((p)=>{
                      let currentFrame = 0;
                      let maxFrames = 0;
                      let video_message = "PENDING";
                      let image_message = "PENDING";
                      try {
                        video_message = JSON.parse(p.note).video_message;
                        const matches = [...video_message.matchAll(/RENDERING_FRAMES \((\d+)\/(\d+)\)/g)];
                        if (matches !== undefined && matches.length > 0 && matches[0].length > 1) {
                          currentFrame = matches[0][1];
                          maxFrames = matches[0][2];
                        }
                      } catch(e) {}
                      try {
                        image_message = JSON.parse(p.note).image_message;
                      } catch(e) {}
                      return(
                        <tr key={p.id}>
                          <td className='tcell-left'>{p.filename}</td>
                          <td>{p.note === null ? "PENDING" : image_message}</td>
                          { maxFrames > 0 ?
                            <td><Row center><Row top={-5} right={20}>FRAMES</Row><CircularProgressWithLabel value={(currentFrame/maxFrames)*100}/></Row></td>
                            :
                            <td>{p.note === null ? "PENDING" : video_message}</td>
                          }
                          
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </Column>
            }
          </Column>
        </Row>
         :
         <Column top={200} width={300}>
            <div className="file-uploader" onDragOver={allowDrop} onDragLeave={leaveDropZone} onDrop={drop}>
              <div style={{position:"absolute", zIndex:1, width:'100%', height:'100%'}}>
                <p style={{marginTop:130}}>Drap & drop your 3D files here</p>
              </div>
              <div style={{position:"absolute", zIndex:10, width:'100%', height:'100%'}}>
                <Row top={30} main={Axis.main.center}>
                  <Button variant="contained" onClick={handleBrowseFile}>Browse</Button>
                  <input id="file" type="file" hidden onChange={handleFileChange} ref={fileRef} accept='.3dm'/>
                </Row>
              </div>
            </div>
          </Column>
       }
      </Column>
      <Dialog open={openDialog} onClose={handleCloseDialog} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">Jewelry Renderer</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          The video and pictures will be delivered to your email. Thank you!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default App;
